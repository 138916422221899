<template>
  <div class="header">
    <div class="header-logo-nav"  id="menu"
    >
      <img class="logo" src="../../assets//home/logo.png" alt="" @click="linkIndex" />
      <div class="nav-header">
        <ul class="nav-list">
          <li
            v-for="(item, index) in navMenu"
            :key="index"
            class="nav-item"
            @click="navClick(item, index, true)"
            @mouseenter="navClick(item, index)"
            @mouseleave="mousego"
          >
            <span>
              {{ item.name }}
            </span>
            <div v-if="activeIndex === index" class="line"></div>
          </li>
        </ul>
      </div>
      <div v-if="isshow === 1 || isshow === 3" :class="[isshow === 1 ? 'header_open' : 'header_open_three']"
      @mouseleave="isshow = 0"  >
        <div class="show_menu" v-for="(item, index) in subMenu" :key="item.value">
          <div
            @mouseenter="level2navClick(item, index)"
            @mouseleave="levle2activeIndex = -1"
            @click="level2navClick1(item, index, true)"
            :class="[
              isshow === 1 ? 'show_menu_name' : 'show_menu_three',
              levle2activeIndex === index ? 'level2_menu_active' : '',
            ]"
          >
            <div v-if="isshow === 1" class="menu_name_icon" :style="{backgroundImage:`url(${getImageUrl(index)})`}"></div>
            <span>{{ item.name }}</span>
          </div>
          <div
            @mouseenter="level2navClick1(a, b)"
            @mouseleave="levle2activeIndex1 = -1"
            @click="level2navClick1(a, b, true)"
            :class="['show_menu_item', levle2activeIndex1 === a.path ? 'level2_menu1_active' : 'show_menu_item']"
            v-for="(a, b) in item.litmList"
            :key="a.value"
          >
            {{ a.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import getrouter from '../../hook/gerRouter'
import {  ref, reactive, nextTick,onMounted,watch } from "vue";
import type { MenuData, subMenuData } from "../../type/home/type";
import { useRouter,useRoute } from "vue-router";
let $router = useRouter();
let $route = useRoute();
const require = window.require
let show_box = ref<boolean>(false)
let isshow = ref<number>(0);
let activeIndex = ref<string | number>(0); // 一级菜单选中索引值
let levle2activeIndex = ref<string | number>(0); // 产品价格二级菜单选中的索引值
let levle2activeIndex1 = ref<string | number>(); // 产品介绍二级菜单选中的索引值
let subMenu = reactive<MenuData[]>([]);
const  getImageUrl = (index) => {
	  // 根据索引生成图像URL
      return new URL(`../../assets/home/navbar-icon${index+1}.png`, import.meta.url).href;
      
    }
const navMenu = reactive<MenuData[]>([
  {
    name: "首页",
    value: "0",
    path: "/",
  },
  {
    name: "产品介绍",
    value: "1",
    path: "",
    subMenu: [
      {
        name: "传信即时通讯",
        value: "0",
        path: "mobileDevelop",
        litmList: [{ value: "移动端/PC端/Pad端", path: "nowmessage" }],
      },
      {
        name: "传信移动办公 ",
        value: "1",
        path: "mobileBank",
        litmList: [{ value: "考勤/待办/流程审批", path: "work" }],
      },
      {
        name: "cherry移动平台",
        value: "2",
        path: "mobileOfficePortal",
        litmList: [
          { value: "开发平台", path: "development" },
          { value: "移动中台", path: "centre" },
          { value: "运营平台", path: "operation" },
        ],
      },
      {
        name: "cherry手机银行",
        value: "2",
        path: "mobileOfficePortal",
        litmList: [{ value: "内容介绍", path: "moblebank" }],
      },
      {
        name: "cherry移动展业",
        value: "2",
        path: "mobileOfficePortal",
        litmList: [{ value: "内容介绍", path: "moblebusiness" }],
      },
      {
        name: "网银",
        value: "2",
        path: "mobileOfficePortal",
        litmList: [
          { value: "个人网银", path: "personbank" },
          { value: "企业网银", path: "companybank" },
        ],
      },
    ],
  },
  {
    name: "立即试用",
    value: "2",
    path: "trynow",
  },
  {
    name: "产品价格",
    value: "3",
    path: "zoology",
    subMenu: [
      {
        name: "产品价格咨询渠道",
        value: "0",
        path: "channel",
        litmList: [],
      },
      {
        name: "法律声明",
        value: "1",
        path: "laws",
        litmList: [],
      },
      {
        name: "使用声明",
        value: "2",
        path: "use",
        litmList: [],
      },
      {
        name: "合法用途承诺",
        value: "2",
        path: "promise",
        litmList: [],
      },
    ],
  },
  {
    name: "生态合作",
    value: "4",
    path: "ecologycooperation",
  },
  {
    name: "公司简介",
    value: "5",
    path: "companyprofile",
  },
  {
    name: "新闻资讯",
    value: "6",
    path: "news",
  },
]);
onMounted(()=>{
  //点击任意位置关闭区域弹窗
 document.addEventListener('click',(e) => {
 
  //获取弹窗对象
  const userCon = document.getElementById('menu');

  //判断弹窗对象中是否包含点击对象
  if(userCon &&　!userCon.contains(e.target)) {

     //如果包含则跳转回之前的页面
     isshow.value = 0;
  }
   
 })

})
// logo点击回首页事件
function linkIndex() {
  $router.push({ path: "/", query: {} });
}

const mousego = () => {
 
  
  console.log('getrouter',getrouter)
  // 调用hooks函数 鼠标移出重定向菜单底部横条显示额位置
  activeIndex.value= getrouter($router.currentRoute.value.path)
}

//路由重定向 
watch(() =>$router.currentRoute.value.path,(newValue,oldValue)=> {
  console.log('watch',newValue);
  activeIndex.value= getrouter(newValue)
})

//导航点击事件
function navClick(item: MenuData, index: number, isClick: boolean = false) {
  activeIndex.value = index;
  if (isClick && index !== 1 && index !== 3) {
    $router.push({ path: item.path, query: {} });
    subMenu = [];
    isshow.value = 0;
  }
  if (index == 1 || index == 3) {
    subMenu = item.subMenu || [];
    if (index == 1) {
      isshow.value = 1;
    } else {
      isshow.value = 3;
    }
  }
  if (!isClick && index !== 1 && index !== 3) {
    subMenu = [];
    isshow.value = 0;
  }
}
//产品价格鼠标和点击事件
const level2navClick = (item: subMenuData, index: string | number) => {
  if (isshow.value === 3) {
    levle2activeIndex.value = index;
  }
};
//产品介绍鼠标和点击事件
const level2navClick1 = (item: any, index: string | number, isClick: boolean = false) => {
  if (isshow.value === 1) {
    levle2activeIndex1.value = item.path;
  }
  if (isClick) {
    $router.push({ path: item.path, query: {} });
    isshow.value = 0;
  }
};
</script>

<style lang="scss" scoped>
@import "./Header.scss";
</style>
