import { Size } from "@/plugins/element"
import { defineStore } from "pinia"
export const useAppStore = defineStore(
  "app",
  () => {
    // 状态
    const state = reactive({
      siderbar: {
        // sidebar 展开状态
        opened: true
      },
      // global size
      size: "default"
    })
    const sidebar = computed(() => state.siderbar)

    // actions
    const toggleSidebar = () => {
      state.siderbar.opened = !state.siderbar.opened
    }

    const size = computed(() => state.size)
    const setSize = (size: Size) => (state.size = size)

    return { state, sidebar, toggleSidebar, size, setSize }
  },
  {
    persist: {
      storage: window.sessionStorage,
      paths: ["state.siderbar.opened", "state.size"]
    }
  }
)
