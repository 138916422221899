export const PriceRoute = [
    {
      path: "/channel",
      name: "channel",
      component: () => import("../../views/price/Channel/index.vue"),
      meta: {
        title: "产品价格咨询渠道",
      },
    },
    {
      path: "/laws",
      name: "laws",
      component: () => import("../../views/price/Laws/index.vue"),
      meta: {
        title: "法律声明", 
      },
    },
    {
      path: "/use",
      name: "use",
      component: () => import("../../views/price/Use/index.vue"),
      meta: {
        title: "使用声明",
      },
    },
    {
      path: "/promise",
      name: "promise",
      component: () => import("../../views/price/Promise/index.vue"),
      meta: {
        title: "合法用途承诺",
      },
    },
    
  ];
  