/**
 * 页面dom级别权限 v-permission="[1,2]""
 */
import { ObjectDirective, DirectiveBinding, App } from "vue"
import { useUserStore } from "@/stores/user"

export interface IDirectiveOptionsWithInstall extends ObjectDirective {
  install?: (app: App) => void
}

const checkPermission = (el: HTMLElement, binding: DirectiveBinding) => {
  const { value } = binding
  const store = useUserStore()
  // 获取权限
  const roles = computed(() => store.state.roles?.map((item) => item.name))

  if (value && Array.isArray(value)) {
    if (value.length > 0) {
      // 传入的权限
      const permissionRoles = value

      // 用户权限中是否拥有传入的权限
      const hasPermission = roles.value?.some((role: string) =>
        permissionRoles.includes(role)
      )

      // 用户无权限
      if (!hasPermission) {
        // 指令权限缺点 移除Dom后 无法恢复
        return el.parentNode?.removeChild(el)
      }
    } else {
      throw new Error(`需要传入权限数组! v-permission="['admin','editor']"`)
    }
  }
}

// 默认相当于 mounted and updated
const plugin = (el: HTMLElement, binding: DirectiveBinding) => {
  checkPermission(el, binding)
}

export default plugin as IDirectiveOptionsWithInstall
