import Layout from "@/layout/index.vue"

export const SinglePageRoute = [
  {
    path: "/trynow",
    name: "trynow",
    component: () => import("../../views/trynow/index.vue"),
    meta: {
      title: "立即试用",
    },
  },
  {
    path: "/ecologycooperation",
    name: "ecologycooperation",
    component: () => import("../../views/ecologycooperation/index.vue"),
    meta: {
      title: "生态合作",
    },
  },
  {
    path: "/companyprofile",
    name: "companyprofile",
    component: () => import("../../views/companyprofile/index.vue"),
    meta: {
      title: "公司简介",
      
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../../views/news/index.vue"),
    meta: {
      title: "新闻资讯",
    },
  },
];
