import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from "vue-router"
import { ProductIntroductionRoute } from "./modules/ProductIntroduction"
import { SinglePageRoute } from "./modules/SinglePage"
import { PriceRoute } from "./modules/price"
import Home  from "@/views/home/index.vue"
const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name:'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: "/",
    component: () => import('../views/home/index.vue'),
    redirect: "/home",
    children:[]
  },
  {
    path: '/:pathMatch(.*)', //所有未匹配的路由重定向到home页面
    redirect: '/'
  }
  
]

export const routes = [
  
  // ...redirectPages,
  // ...asyncRoutes,
  //  ...externalLinkRoutes,
   ...ProductIntroductionRoute,
  //  ...errorPageRoutes,
   ...SinglePageRoute,
   ...PriceRoute,
   ...constantRoutes,
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.afterEach(() => {
  // 页面滚动复位
  window.scrollTo(0, 0);
});

export default router

